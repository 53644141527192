.searchBar {
  height: 65px;
  width: calc(100% - 250px);
  background-color: #fff;

  border-bottom-width: 2px;
  border-color: #ECEEF2;
  border-style: solid;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 250px;
  z-index: 99;
}
.searchBar.searchbar-full-width{
  left: 0;
  width: 100%;
}
.toggleIcon {
  width: 25px;
  height: 25px;
}
.header-user-info{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
.header-user-info p{
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: 12px;
}
.header-user-info span{
  font-size: 10px;
  background-color: #00c4b3;
  color: white;
  padding: 1px 5px;
  border-radius: 100vh;
}