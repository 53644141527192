.tableListCont {
  height: 63vh;
}

.transaccionesPage {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.status {
  font-size: larger;
  font-weight: bold;
}

.transaccionesPage {
  display: flex;
  flex: 1;
}

.statistic {
  font-size: small;
  font-weight: normal;
}

