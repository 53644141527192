.login-page{
    display: flex;
    height: 100vh;
}
.form-login-btn{
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.row{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.text-center{
    text-align: center;
}