.tableListContainer {
  height: 100%;
  box-sizing: border-box;
}
.tableContainer {
  justify-content: center;
  align-items: center;
  border: 1px solid #dadada;
  border-radius: 5px;
  background-color: white;
  width: auto;
  overflow-x: scroll;
  height: 100%;
}
.titleTable {
  display: flex;
  align-items: flex-start;
}


.itemList {
  border-top: 1px solid #dadada;
}
.item {
  border-bottom: 0.2px solid #dadada8a;
  border-right: 0.3px solid #dadada8a;
  max-height: 1.6rem;
  min-height: 1.6rem;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: inherit;
  scrollbar-width: auto;
  padding-inline: 8px;
  text-wrap: nowrap;
  max-width: 50rem;
  align-content: center;
}
.titleBox {
  padding-inline: 5px;
  border: 0.3px solid #dadada8a;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  max-height: 1.6rem;
  min-height: 1.6rem;
}
.filterBox {
  border-bottom: 0.2px solid #dadada8a;
  border-right: 0.3px solid #dadada8a;
  max-height: 2rem;
  min-height: 2rem;
  overflow-y: auto;
  -ms-overflow-style: inherit;
  scrollbar-width: auto;
  padding-inline: 8px;
}
/* UTILS */
.texto-en-linea {
  white-space: nowrap; /* Evitar saltos de línea */
  overflow: hidden; /* Ocultar texto que se desborde */
  text-overflow: ellipsis; /* Mostrar puntos suspensivos (...) para indicar que hay más texto */
}

.separator {
  border: 1px solid #bdbdbd;
}