@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
   url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
   url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 300;
 }

 @font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
   url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
   url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 300;
 }

 body, html, #root {
  height: 100%;
 }

 body {
  margin: 0;
  font-family: 'RobotoRegular';
  font-size: small;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary)
}

:root {
  --text-primary: #424242;
  --text-disabled: #B7B7B7;
  --text-accent: #00998C;
  --text-muted: #B7B7B7;
  --border-color: #EEEEEE;
  --light-gray: #EEEEEE;
  --accent-color: #00C4B3;
  --accent-light-color: #ccebe8;
  --expand-animation-timing: ease;
  --expand-animation-length: 400ms
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center {
  text-align: center;
}