.buttonProps {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.generalBtn {
  padding-inline: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
}

.disableBtn {
  opacity: 0.6;
  background-color: #c4c4c4 !important;
  cursor: not-allowed;
}

.generalBtn:hover {
  opacity: 0.6;
}

.button-icon {
  padding: 0px;
  padding-left: 5px;
}

.primary {
  background-color: #00c4b3;
  color: white;
}

.red {
  background-color: #e53935;
  color: white;
}

.white {
  background-color: #dedede;
  color: black;
}

.blue {
  background-color: #5688BE;
  color: white;
}

.yellow {
  background-color: #f5aa28;
  color: white;
}

.green {
  background-color: #35ac47;
  color: white;
}
