.sidebar {
  border-right: 1rem;
  border-right-color: #ECEEF2;
  border-right-width: 2px;
  border-right-style: solid;
  height: 100vh;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  display: flex;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 650ms;
}

.sidebar.active {
  left: 0;
  position: relative;
  transition: 450ms;
}

.logo {
  padding: 10px;
  width: 130px;
  object-fit: contain;
  align-self: center;
}

.linkButton {
  width: 80%;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: start;
  padding-left: 5px;
  color: #202224;
}

.linkButton:hover {
  background-color: #00c4b3;
  color: #fff;
  border-radius: 10px;
}
.linkButton.active {
  background-color: #00c4b3;
  color: #fff;
  border-radius: 10px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-icon {
  padding-inline: 10px;
  fill: #f5f5f5;
}

.dropdownMenu::part(base) {
  background-color: transparent;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px !important;
  text-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 100% !important;
}
.dropdownMenu::part(base):hover {
  background-color: #00c4b3;
  color: white;
  border-radius: 10px;
}
.dropdownMenu.active {
  background-color: #00c4b3;
  color: #fff;
  border-radius: 10px;
}
.dropdownMenu.active::part(label) {
  color: #fff;
}

.dropdownMenu::part(label) {
  padding-left: 0px;
  padding-right: 37px;
}

.dropdownContainer::part(base) {
  position: relative;
}
.dropdownContainer::part(base):hover {
  border-radius: 20px;
}
.entorno {
  font-size: smaller;
  padding: 10px;
  object-fit: contain;
  align-self: center;
  text-transform: lowercase;
}