.floatMenu {
  position: absolute;
  left: 5px;
  top: 15px;
}

.alumnobtn {
  background-color: rgba(0, 196, 180, 0.11);
  flex: 1;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
}

.alumnobtn.selected {
  background-color: #00c4b3;
  color: white;
}

.alumnobtn:hover {
  cursor: pointer;
  opacity: 0.85;
}