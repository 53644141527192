.box {
  border-bottom: 0.2px solid #dadada8a;
  border-right: 0.3px solid #dadada8a;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  text-align: center;
  padding-inline: 8px;
  max-height: 3rem;
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.validacionesContainer {
  display: flex;
  background-color: white;
  border-radius: 0.5rem;
  width: min-content;
}
