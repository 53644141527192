.estadoGeneralContainer {
  display: flex;
  flex-direction: column;
}
.buttonsMirror {
  width: 100%;
  display: flex;
  flex: 0.4;
  flex-direction: row;
  justify-content: space-around;
  padding: 15px;
}

.mirrorStatusTable {
  justify-content: center;
  align-items: center;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}
.titleTable {
  display: flex;
  flex-grow: 1;
}

.separator {
  border: 1px solid #bdbdbd;
}
.itemList {
  border-top: 1px solid #bdbdbd;
}

.tableList {
  justify-content: center;
  align-items: center;
  border: 1px solid #dadada;
  border-radius: 5px;
  background-color: white;
}
.customItem {
  padding-top: 3px;
  padding-bottom: 3px;
  border-right: 1px solid #dadada8a;
  align-self: center;
}