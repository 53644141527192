.sectionBtn {
  background-color: rgb(238, 255, 254);
  border: 2px rgb(193, 235, 231) solid;
  flex: 1;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  opacity: 0.8;
}

.sectionBtn.selected {
  background-color: rgb(0, 196, 179);
  color: white;
  opacity: 1;
}

.sectionBtn:hover {
  cursor: pointer;
  opacity: 0.85;
}