:root {
  --verde-agua-light: "#AAE5E0";
  --verde-agua: "#00C4b3";
  --verde-agua-dark: "#00998C";
  --magenta: "#E31D93";
  --magenta-light: "#F780C7";
  --magenta-dark: "#BB1177";
  --amarillo: "#F5A800";
  --amarillo-light: "#F5CDA3";
  --amarillo-dark: "#D69300";
}